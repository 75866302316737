import React, { useState, useEffect } from 'react';
import { Button } from './components/ui/button';
import { Input } from './components/ui/input';
import { Github, Twitter, Search, Coffee, DollarSign, Moon, Sun, Menu, X } from 'lucide-react';
import projectsData from './projects.json';
import ProjectCard from './components/ProjectCard';
import TestimonialCard from './components/TestimonialCard';

const AIEngineerPortfolio = () => {
  const [activeCategory, setActiveCategory] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('latest');
  const [darkMode, setDarkMode] = useState(true);
  const [visibleProjects, setVisibleProjects] = useState(6);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const categories = ['All', ...new Set(projectsData.map(project => project.category))];

  const filteredAndSortedProjects = projectsData
    .filter(p => (activeCategory === 'All' || p.category === activeCategory) &&
      (p.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      p.description.toLowerCase().includes(searchTerm.toLowerCase())))
    .sort((a, b) => {
      if (sortBy === 'latest') return 0;
      return a.name.localeCompare(b.name);
    });

  const testimonials = [
    {
      name: "Standspurfahrer",
      role: "Logo Redmond User",
      company: "Civitai User",
      quote: "If I ever found a company I'll use this LoRA. Thank you!"
    },
    {
      name: "Coremar",
      role: "PIXALART Redmond User",
      company: "Civitai User",
      quote: "Works great. For me the best pixel art lora so far."
    },
    {
      name: "20m8",
      role: "CinematicRedmond User",
      company: "Civitai User",
      quote: "This model is the best I've ever used honestly! Amazing work I don't know why it's so underrated. It outperforms the best models for me"
    }
  ];

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-fade-in');
        }
      });
    }, { threshold: 0.1 });

    document.querySelectorAll('.animate-on-scroll').forEach(el => observer.observe(el));

    return () => observer.disconnect();
  }, []);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.documentElement.classList.toggle('dark');
  };

  const loadMoreProjects = () => {
    setVisibleProjects(prevVisible => prevVisible + 6);
  };

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-gray-100' : 'bg-gray-100 text-gray-900'} transition-colors duration-300`}>
      <nav className={`fixed w-full ${darkMode ? 'bg-background-dark' : 'bg-background-light'} bg-opacity-90 backdrop-filter backdrop-blur-lg z-50 shadow-lg`}>
        <div className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <span className="text-2xl font-bold text-primary-dark">ArtificialGuyBR</span>
            <div className="hidden md:flex items-center space-x-6">
              {['About', 'Projects', 'Testimonials', 'Support', 'Contact'].map((item) => (
                <a
                  key={item}
                  href={`#${item.toLowerCase()}`}
                  className={`text-lg font-medium ${darkMode ? 'text-text-dark' : 'text-text-light'} hover:text-primary-light transition-colors duration-300`}
                >
                  {item}
                </a>
              ))}
              <button onClick={toggleDarkMode} className="focus:outline-none">
                {darkMode ? <Sun className="w-6 h-6 text-primary-light" /> : <Moon className="w-6 h-6 text-primary-dark" />}
              </button>
            </div>
            <div className="md:hidden">
              <button onClick={() => setMobileMenuOpen(!mobileMenuOpen)} className="focus:outline-none">
                {mobileMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
              </button>
            </div>
          </div>
        </div>
        {mobileMenuOpen && (
          <div className="md:hidden">
            {['About', 'Projects', 'Testimonials', 'Support', 'Contact'].map((item) => (
              <a
                key={item}
                href={`#${item.toLowerCase()}`}
                className={`block py-2 px-4 text-lg font-medium ${darkMode ? 'text-text-dark' : 'text-text-light'} hover:text-primary-light transition-colors duration-300`}
                onClick={() => setMobileMenuOpen(false)}
              >
                {item}
              </a>
            ))}
            <button onClick={toggleDarkMode} className="w-full text-left py-2 px-4 focus:outline-none">
              {darkMode ? 'Light Mode' : 'Dark Mode'}
            </button>
          </div>
        )}
      </nav>
      <header className="container mx-auto px-4 pt-32 pb-20 text-center">
        <h1 className="text-4xl md:text-6xl font-bold mb-4 animate-fade-in-down leading-tight overflow-visible">
          <span className="bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text inline-block">
            Transforming Ideas into AI Reality.
          </span>
        </h1>
        <h2 className="text-3xl mb-8 animate-fade-in-down" style={{animationDelay: '0.2s'}}>
          ArtificialGuyBR - Independent AI Engineer.
        </h2>
        <p className="text-xl mb-8 max-w-2xl mx-auto animate-fade-in-down" style={{animationDelay: '0.4s'}}>
        Explore our extensive collection of AI models designed to address a wide range of challenges and opportunities.
        </p>
        <div className="animate-fade-in-down" style={{animationDelay: '0.6s'}}>
          <a href="#projects">
            <Button className="bg-primary-dark hover:bg-primary text-white font-bold py-3 px-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
              Discover the Future of AI
            </Button>
          </a>
        </div>
      </header>

      <section id="about" className={`py-24 ${darkMode ? 'bg-background-dark' : 'bg-background-light'}`}>
        <div className="container mx-auto px-6">
          <h2 className="text-center mb-12 text-primary-dark">About Me</h2>
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="md:w-1/3 mb-8 md:mb-0">
              <img
                src={require('./assets/images/about-me-image.jpg')}
                alt="ArtificialGuyBr"
                className="rounded-full w-64 h-64 object-cover mx-auto border-4 border-primary-light animate-on-scroll"
              />
            </div>
            <div className="md:w-2/3 md:pl-8">
              <h3 className="mb-4 text-secondary-dark">AI Engineer & Researcher</h3>
              <p className="text-lg mb-4">Passionate about artificial intelligence and its potential to improve the world. A self-taught engineer developing models for OSS, individuals, and companies. With more than 70 projects released publicly!</p>
              <ul className="list-disc list-inside">
                <li>Expertise in machine learning and deep learning</li>
                <li>Experienced in natural language processing and computer vision</li>
                <li>Committed to ethical AI development and open-source contributions</li>
              </ul>
            </div>
          </div>
        </div>
      </section>


      <section id="projects" className="py-24">
        <div className="container mx-auto px-6">
          <h3 className="text-3xl font-semibold mb-8 text-center bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text">Groundbreaking Projects</h3>
          <div className="flex flex-col md:flex-row justify-between items-center mb-8">
            <div className="flex items-center w-full md:w-auto mb-4 md:mb-0">
              <Input
                type="text"
                placeholder="Search projects..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="mr-2"
              />
              <Button onClick={() => setSearchTerm('')} className={`${darkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-200 hover:bg-gray-300'} transition-colors duration-300`}>
                <Search size={20} className={darkMode ? 'text-white' : 'text-gray-700'} />
              </Button>
            </div>
            <div className="flex items-center">
              <span className="mr-2">Sort by:</span>
              <Button onClick={() => setSortBy('latest')} className={`mr-2 ${sortBy === 'latest' ? (darkMode ? 'bg-blue-500' : 'bg-blue-600') : (darkMode ? 'bg-gray-700' : 'bg-gray-300')} ${darkMode ? 'text-white' : 'text-gray-800'} transition-colors duration-300`}>
                Latest
              </Button>
              <Button onClick={() => setSortBy('az')} className={`${sortBy === 'az' ? (darkMode ? 'bg-blue-500' : 'bg-blue-600') : (darkMode ? 'bg-gray-700' : 'bg-gray-300')} ${darkMode ? 'text-white' : 'text-gray-800'} transition-colors duration-300`}>
                A-Z
              </Button>
            </div>
          </div>
          <div className="flex flex-wrap justify-center mb-8 gap-2">
            {categories.map(category => (
              <Button
                key={category}
                onClick={() => setActiveCategory(category)}
                className={`${activeCategory === category ? 'bg-blue-500' : 'bg-gray-700'} text-white hover:bg-blue-600 transition-colors duration-300`}
              >
                {category}
              </Button>
            ))}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredAndSortedProjects.slice(0, visibleProjects).map((project, index) => (
              <div key={project.name} className="animate-on-scroll" style={{animationDelay: `${index * 0.1}s`}}>
                <ProjectCard {...project} darkMode={darkMode} />
              </div>
            ))}
          </div>
          {visibleProjects < filteredAndSortedProjects.length && (
            <div className="text-center mt-8">
              <Button onClick={loadMoreProjects} className="bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-300">
                Load More Projects
              </Button>
            </div>
          )}
        </div>
      </section>

      <section id="testimonials" className={`py-24 ${darkMode ? 'bg-gray-800' : 'bg-gray-200'} bg-opacity-50`}>
        <div className="container mx-auto px-6">
          <h3 className="text-3xl font-semibold mb-12 text-center bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text">What People Say</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="animate-on-scroll" style={{animationDelay: `${index * 0.1}s`}}>
                <TestimonialCard {...testimonial} darkMode={darkMode} />
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="donate" className="py-24">
        <div className="container mx-auto px-6 text-center">
          <h3 className="text-3xl font-semibold mb-8 bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text">Support My Work</h3>
          <p className="text-xl mb-8 max-w-2xl mx-auto">Your support fuels groundbreaking AI research and development. Together, we can push the boundaries of what's possible and create a better future through technology.</p>
          <div className="flex flex-wrap justify-center gap-6">
            <a href="https://www.patreon.com/user?u=81570187" target="_blank" rel="noopener noreferrer">
              <Button className="bg-red-500 hover:bg-red-600 flex items-center transition-colors duration-300">
                <DollarSign className="mr-2" /> Support on Patreon
              </Button>
            </a>
            <a href="https://ko-fi.com/artificialguybr" target="_blank" rel="noopener noreferrer">
              <Button className="bg-purple-500 hover:bg-purple-600 flex items-center transition-colors duration-300">
                <DollarSign className="mr-2" /> Donate via Ko-fi
              </Button>
            </a>
            <a href="https://www.buymeacoffee.com/jvkape" target="_blank" rel="noopener noreferrer">
              <Button className="bg-blue-400 hover:bg-blue-500 flex items-center transition-colors duration-300">
                <Coffee className="mr-2" /> Buy me a coffee
              </Button>
            </a>
          </div>
        </div>
      </section>

      <section id="contact" className={`py-24 ${darkMode ? 'bg-gray-800' : 'bg-gray-200'} bg-opacity-50`}>
        <div className="container mx-auto px-6 text-center">
          <h3 className="text-3xl font-semibold mb-8 bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text">Let's Shape the Future Together</h3>
          <p className="text-xl mb-8 max-w-2xl mx-auto">Whether you're looking to collaborate on a project, need AI consulting, or want to discuss the future of technology, I'm here to connect.</p>
          <a href="mailto:artificialguybr@proton.me">
            <Button className="bg-blue-500 text-white hover:bg-blue-600 mb-8 transition-colors duration-300">
              Get in Touch
            </Button>
          </a>
          <div className="flex justify-center space-x-6">
            <a href="https://github.com/artificialguybr" className="text-gray-400 hover:text-blue-400 transition-colors duration-300">
              <Github size={24} />
            </a>
            <a href="https://twitter.com/@artificialguybr" className="text-gray-400 hover:text-blue-400 transition-colors duration-300">
              <Twitter size={24} />
            </a>
          </div>
        </div>
      </section>

      <footer className={`${darkMode ? 'bg-gray-900' : 'bg-gray-200'} py-12`}>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-between items-center">
            <div className="w-full md:w-1/3 text-center md:text-left mb-6 md:mb-0">
              <h3 className="text-2xl font-bold text-primary-dark mb-2">ArtificialGuyBR</h3>
              <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>Transforming Ideas into AI Reality</p>
            </div>
            <div className="w-full md:w-1/3 text-center mb-6 md:mb-0">
              <h4 className="text-lg font-semibold mb-2">Quick Links</h4>
              <ul className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                {['About', 'Projects', 'Testimonials', 'Support', 'Contact'].map((item) => (
                  <li key={item} className="mb-1">
                    <a href={`#${item.toLowerCase()}`} className="hover:text-primary-light transition-colors">{item}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full md:w-1/3 text-center md:text-right">
              <h4 className="text-lg font-semibold mb-2">Connect With Me</h4>
              <div className="flex justify-center md:justify-end space-x-4">
                <a href="https://github.com/artificialguybr" className="text-gray-400 hover:text-primary-light transition-colors">
                  <Github size={24} />
                </a>
                <a href="https://twitter.com/@artificialguybr" className="text-gray-400 hover:text-primary-light transition-colors">
                  <Twitter size={24} />
                </a>
              </div>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-700 text-center">
            <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>&copy; 2022 Vitor Labs LTDA. All rights reserved.</p>
          </div>
        </div>
      </footer>

      <style jsx>{`
        @keyframes fadeInDown {
          from {
            opacity: 0;
            transform: translateY(-20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        .animate-fade-in-down {
          animation: fadeInDown 0.5s ease-out forwards;
        }
        .animate-fade-in {
          animation: fadeIn 0.5s ease-out forwards;
        }
      `}</style>
    </div>
  );
};

export default AIEngineerPortfolio;

